import React from "react"
import MainLayout from "../components/Layout/MainLayout"
import { graphql } from "gatsby"
import BlogUdemyTemplate from "./BlogUdemyTemplate"
import BlogLeadGenerationTemplate from "./BlogLeadGenerationTemplate"

export default function Template({
                                   data,
                                   location,
                                   children,
                                   ...rest
                                 }) {
  const { eur } = data.site.siteMetadata.pricingConfiguration
  const { mdx } = data

  return (
    <MainLayout {...rest}>
      <BlogUdemyTemplate
        eur={eur}
        mdx={mdx}
      />
    </MainLayout>
  )
}

export const pageQuery = graphql`
  query PostQuery($id: String!) {
  site {
    siteMetadata {
      pricingConfiguration {
        eur {
          ebook {
            price
            oldPrice
            url
          }
          complete {
            price
            oldPrice
            url
          }
          video {
            price
            oldPrice
            url
          }
        }
      }
    }
  }
    mdx(id: { eq: $id }) {
      id
      body
      frontmatter {
        slug
        title
        author
        featuredImage {
          childImageSharp {
            fluid(maxWidth: 800) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
